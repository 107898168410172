import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Button, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import {
    fetchJsonData,
    writeJsonToPublicAssets,
} from '../helpers/public-json-io'
import { HomeTextWidget } from '../components/HomeTextWidget'

const filename = 'home-text-data.json'

export interface HomeText {
    title: string
    subtitle: string
}

export function HomeTextSection() {
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const { oidcUser } = useReactOidc()
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const toast = useToast()
    const [text, setText] = useState<HomeText | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
        fetchTextData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheBuster, oidcUser])

    async function handleSaveText(newText: HomeText) {
        const emptyText = !newText.subtitle || !newText.title
        if (emptyText) {
            toast({
                title:
                    'Cannot publish empty home text. Ensure both a title and subtitle are set.',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const noChanges =
            newText.title === text?.title && newText.subtitle === text?.subtitle
        if (noChanges) {
            toast({
                title: 'No changes detected, not publishing',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        handleUpdateText(newText)
    }

    async function handleUpdateText(newText: HomeText) {
        setLoading(true)
        try {
            await writeJsonToPublicAssets(
                filename,
                newText,
                uploadFile,
                getSignedUrl,
            )
            setCachebuster(Date.now())
        } catch (e: any) {
            toast({
                title: 'Oh no something went wrong!',
                description:
                    'There was an error updating the text - please check the console and contact the dev team if the problem persists',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    async function fetchTextData() {
        setLoading(true)
        try {
            const data: any = await fetchJsonData(
                filename,
                cacheBuster.toString(),
            )
            console.debug('Fetched message data.', data)
            setText(data)
        } catch (e: any) {
            console.error("Couldn't retrieve text data.", e)
            setError("Couldn't retrieve text data.")
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <Text fontSize={'xx-large'} fontWeight="bold">
                Home Text
            </Text>
            <Text paddingBottom={4}>
                This is displayed to users who aren't logged in. Newlines (when
                you press enter) will be respected for the Title always, and for
                the Subtitle in mobile view only. Both will be automatically
                upper case.{' '}
                <b>
                    Ensure you test in dev for a visual check before you change
                    production
                </b>
            </Text>
            {error ? (
                <Box>
                    <Text color="red">{`Error: ${error}`}</Text>
                    <Button onClick={fetchTextData}>Try Again</Button>
                </Box>
            ) : (
                text && (
                    <HomeTextWidget
                        text={text}
                        handleSaveText={handleSaveText}
                        loading={loading}
                    />
                )
            )}
        </Box>
    )
}
