import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ImageUpload } from '../components/ImageUpload'
import { ImageBox } from './FeaturesImagesSection'

export interface HomeText {
    title: string
    subtitle: string
}

export function PrizeImageSection() {
    return (
        <Box width="full">
            <Text fontSize={'xx-large'} fontWeight="bold">
                Prize Images
            </Text>
            <Text mb="4">Prizes images show up on the home page.</Text>
            <Flex flexWrap="wrap" gap="4" mt="2">
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Prize (Desktop)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 2320x396px
                    </Text>
                    <ImageUpload
                        fileName={'prize-image-desktop.png'}
                    ></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Prize (Tablet)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 1412x528px
                    </Text>
                    <ImageUpload
                        fileName={'prize-image-tablet.png'}
                    ></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Prize (Mobile)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 1560x1276px
                    </Text>
                    <ImageUpload
                        fileName={'prize-image-mobile.png'}
                    ></ImageUpload>
                </ImageBox>
            </Flex>
        </Box>
    )
}
