import { Box, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { environment } from '../../environments/environment'

export function CustomCompetitionSection() {
    const url = environment.frontendUrl
    return (
        <Box>
            <Text fontSize="xx-large" fontWeight="bold">
                Custom Competition Setup
            </Text>
            <Text mb="4">
                Ensure you are logged in to The Game frontend, and remember to
                untick the option to receive emails from people joining the
                competition
            </Text>
            <Link
                href={`${url}/comp/create?custom-code`}
                display="flex"
                alignItems="center"
                gap="2"
                width="fit-content"
                isExternal={true}
            >
                <FaExternalLinkAlt />
                <Text textDecoration="underline">
                    Go to custom competition setup form
                </Text>
            </Link>
        </Box>
    )
}
