import React, { useState } from 'react'
import { Box, Text, Button, Textarea, Flex } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { CardComponent } from '../components/CardComponent'
import { ManageSeasonBaseProps } from './ManageSeason'
import { FaSave } from 'react-icons/fa'

export function CompetitionNameSection({
    season,
    refetch,
}: ManageSeasonBaseProps) {
    const [configure] = useConfigureSeason()
    const [nameOverride, setNameOverride] = useState(
        season.config?.nameOverride || '',
    )
    const [editable, setEditable] = useState(false)

    const submit = async () => {
        if (season.config) {
            await configure({
                season,
                config: {
                    ...season.config,
                    nameOverride,
                },
            })
            await refetch()
        }
        setEditable(false)
    }

    return (
        <Box>
            <Text fontSize={'x-large'}>Competition Name Override</Text>
            <Text fontSize={'sm'}>
                <i>*Optional. </i>
                Name shown for the official competition
            </Text>
            <Box display="flex">
                <CardComponent
                    title=""
                    content={
                        <Box display="flex" flexDir="row" alignItems="center">
                            {editable ? (
                                <Textarea
                                    onChange={(e) =>
                                        setNameOverride(e.target.value)
                                    }
                                    value={nameOverride}
                                    placeholder="Name Override"
                                />
                            ) : season.config?.nameOverride ? (
                                <Text fontWeight="bold" fontSize="lg" px="3">
                                    {season.config.nameOverride}
                                </Text>
                            ) : (
                                <Text opacity="0.7" fontStyle="italic">
                                    No override
                                </Text>
                            )}
                        </Box>
                    }
                    action={
                        <Flex flexDirection="column" gap="1">
                            <Button
                                colorScheme={editable ? 'gray' : 'blue'}
                                leftIcon={<EditIcon />}
                                onClick={() => setEditable(!editable)}
                            >
                                {editable ? 'Cancel' : 'Edit'}
                            </Button>
                            {editable ? (
                                <Button
                                    colorScheme="blue"
                                    leftIcon={<FaSave />}
                                    onClick={submit}
                                >
                                    Save
                                </Button>
                            ) : null}
                        </Flex>
                    }
                />
            </Box>
        </Box>
    )
}
