import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { SearchForm } from './SearchForm'
import { SeasonForm, SeasonPageName } from './ManageSeason/SeasonForm'
import { NewSeasonForm } from './ManageSeason/NewSeasonForm'
import { ConfigAndToolsForm } from './ConfigAndTools'

export function Routes({
    hasCommercialAccess,
    hasAdminAccess,
}: {
    hasCommercialAccess: boolean
    hasAdminAccess: boolean
}): JSX.Element {
    if (hasCommercialAccess)
        return (
            <Switch>
                <Route path="/search" component={SearchForm} />

                <Route path="/seasons/newseason" component={NewSeasonForm} />

                <Route
                    path="/seasons/:seasonId/:page"
                    render={({ match }) => (
                        <SeasonForm
                            seasonId={match.params.seasonId}
                            page={match.params.page as SeasonPageName}
                        />
                    )}
                />

                <Route
                    path="/seasons/:seasonId"
                    render={({ match }) => (
                        <SeasonForm seasonId={match.params.seasonId} />
                    )}
                />
                <Route path="/seasons" component={SeasonForm} />

                <Route
                    path="/config/content"
                    render={() => <ConfigAndToolsForm tab="content" />}
                />
                <Route
                    path="/config/sponsors"
                    render={() => <ConfigAndToolsForm tab="sponsors" />}
                />
                <Route
                    path="/config/custom-comp"
                    render={() => <ConfigAndToolsForm tab="custom-comp" />}
                />
                <Route
                    path="/config/message"
                    render={() => <ConfigAndToolsForm tab="message" />}
                />
                <Route
                    path="/config/notifications"
                    render={() => <ConfigAndToolsForm tab="notifications" />}
                />
                <Route
                    path="/config/deep-links"
                    render={() => <ConfigAndToolsForm tab="deep-links" />}
                />
                {hasAdminAccess ? (
                    <Route
                        path="/config/app-versions"
                        render={() => <ConfigAndToolsForm tab="app-versions" />}
                    />
                ) : (
                    <Route path="/config/app-versions">
                        <Redirect to="/config/content" />
                    </Route>
                )}
                <Route path="/config" component={ConfigAndToolsForm} />

                <Route path="/">
                    <Redirect to="/search" />
                </Route>
            </Switch>
        )

    return (
        <Switch>
            <Route path="/search" component={SearchForm} />

            <Route path="/">
                <Redirect to="/search" />
            </Route>
        </Switch>
    )
}
