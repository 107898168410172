import {
    Box,
    Text,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Select,
    Flex,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SportName, useCreateSeason } from '../data'

type Season = {
    sport: string
    season: string
}

const emptySeason: Season = {
    sport: '',
    season: '',
}

export function NewSeasonForm() {
    const history = useHistory()
    const [newSeason, setNewSeason] = useState<Season>(emptySeason)
    const [createSeason] = useCreateSeason()

    const onChange = (prop: 'sport' | 'season', value: string) => {
        setNewSeason((originalSeason) => {
            if (prop === 'sport') {
                originalSeason['sport'] = value as SportName
                originalSeason['season'] = ''
            } else {
                originalSeason[prop] = value
            }
            return { ...originalSeason }
        })
    }

    const validSeason = (checkSeason: Season) => {
        return checkSeason.sport !== '' && checkSeason.season !== ''
    }

    const onCreateSeasonPress = async () => {
        if (validSeason(newSeason)) {
            const body = JSON.stringify(newSeason)
            await createSeason(body)
            history.goBack()
        } else {
            console.log('invalid season')
        }
    }

    const getDates = (sport: string) => {
        const thisYear = new Date().getFullYear()
        if (sport === 'Cricket') {
            return [
                `${thisYear}-${(thisYear + 1).toString().slice(2)}`,
                `${thisYear + 1}-${(thisYear + 2).toString().slice(2)}`,
            ]
        }
        return [`${thisYear}`, `${thisYear + 1}`]
    }

    return (
        <Box px="10">
            <Box>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/seasons">
                            Manage Seasons
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/seasons/newseason`}>
                            Create New Season
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <Box display="flex" justifyContent="center">
                <Flex flexDirection="column" width="400px" gap="4">
                    <Text
                        // textAlign="center"
                        fontSize={'xx-large'}
                        fontWeight="bold"
                    >
                        Create New Season
                    </Text>

                    <Box>
                        <Text mb="2" mt="4">
                            Sport (Required)
                        </Text>
                        <Select
                            placeholder="Select sport"
                            onChange={(e) => onChange('sport', e.target.value)}
                            value={newSeason.sport}
                        >
                            <option value="AFL">AFL</option>
                            <option value="AFLW">AFLW</option>
                            <option value="Cricket">Cricket</option>
                            <option value="NRL">NRL</option>
                            <option value="WAFL">WAFL</option>
                        </Select>
                    </Box>

                    <Box>
                        <Box>
                            <Text>Season</Text>
                            <Select
                                placeholder="Select season"
                                onChange={(e) =>
                                    onChange('season', e.target.value)
                                }
                                value={newSeason.season}
                            >
                                {getDates(newSeason.sport).map(
                                    (date, index) => (
                                        <option key={index} value={date}>
                                            {date}
                                        </option>
                                    ),
                                )}
                            </Select>
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent="space-between" mt="2">
                        <Button
                            variant="outline"
                            onClick={() => history.goBack()}
                            colorScheme="blue"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onCreateSeasonPress()}
                            colorScheme="blue"
                            disabled={!validSeason(newSeason)}
                        >
                            Create season
                        </Button>
                    </Box>
                </Flex>
            </Box>
        </Box>
    )
}
