import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Box,
    Text,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { useGetUserInfoByEmail } from './data'
import UserResult from './UserResult/UserResult'
import { Notification } from './UserResult/components/Notification'

const StyledForm = styled('form')({})

export function SearchForm() {
    const [
        getInfo,
        { isError, error, reset, data, isLoading },
    ] = useGetUserInfoByEmail()

    const [email, setEmail] = React.useState('')

    return (
        <Box>
            <Grid px="10" templateColumns="repeat(4, 1fr)" gap="10">
                <FormControl as={GridItem} id="email">
                    <StyledForm
                        onSubmit={(e) => {
                            e.preventDefault()
                            getInfo(email)
                        }}
                    >
                        <FormLabel htmlFor="email" fontSize={'xx-large'}>
                            Search User
                        </FormLabel>
                        <Grid templateColumns="repeat(4, 1fr)" gap="2">
                            <GridItem colSpan={3}>
                                <Input
                                    name="email"
                                    type="text"
                                    placeholder="Enter user's email"
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                />
                            </GridItem>
                            <GridItem
                                as={Button}
                                onClick={() => {
                                    getInfo(email)
                                }}
                                isLoading={isLoading}
                                disabled={!email}
                                colSpan={1}
                            >
                                Search
                            </GridItem>
                        </Grid>
                    </StyledForm>
                </FormControl>
                <GridItem
                    as={Divider}
                    mt={6}
                    mb={10}
                    borderColor="black"
                    colSpan={4}
                />
                {data && !isError && (
                    <GridItem colSpan={4}>
                        <UserResult key={data.subject} data={data} />
                    </GridItem>
                )}
                {isError && (
                    <Notification
                        type="error"
                        message={error as string}
                        hideTimeout={5000}
                        onNotificationClose={reset}
                    />
                )}
            </Grid>
            {!data && !isLoading && (
                <Box px="10" mt={10}>
                    <Text
                        fontSize="md"
                        as="i"
                        display="flex"
                        justifyContent="center"
                    >
                        Search for a user above to inspect their details.
                    </Text>
                    <Box as={Divider} mt={20} borderColor="black" />
                </Box>
            )}
        </Box>
    )
}
