import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ImageUpload } from '../components/ImageUpload'

export function ImageBox({ children, ...rest }) {
    return (
        <Flex
            width={480}
            direction="column"
            padding={4}
            border="1px #65707d77 solid"
            borderRadius={8}
            {...rest}
        >
            {children}
        </Flex>
    )
}

export function FeaturedImagesSection() {
    return (
        <Box width="full">
            <Text fontSize={'xx-large'} fontWeight="bold">
                Images & Assets
            </Text>
            <Text mb="4">App wide images and assets management</Text>
            <Flex flexWrap="wrap" gap="4">
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Featured Image
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 838x838px
                    </Text>
                    <ImageUpload fileName={'featuredimage.png'}></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Featured Image (small)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 375x290px
                    </Text>
                    <ImageUpload
                        fileName={'featuredimage-small.png'}
                    ></ImageUpload>
                </ImageBox>
                <ImageBox>
                    <Text fontSize={'x-large'} fontWeight="bold">
                        Featured Image (mobile)
                    </Text>
                    <Text fontSize={'sm'} mb={4}>
                        .png format, 758x882px
                    </Text>
                    <ImageUpload
                        fileName={'featuredimage-mobile.png'}
                    ></ImageUpload>
                </ImageBox>
            </Flex>
        </Box>
    )
}
