import React, { useState } from 'react'
import { Box, Text, Button, Input, Flex } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { CardComponent } from '../components/CardComponent'
import { ManageSeasonBaseProps } from './ManageSeason'
import { FaSave } from 'react-icons/fa'
import { SeasonConfig } from '../data'

export function CompetitionDataSection({
    season,
    refetch,
}: ManageSeasonBaseProps) {
    const [configure] = useConfigureSeason()
    const [ingestionCompId, setIngestionCompId] = useState(
        season.config?.ingestionCompId || '',
    )
    const [tabTouchCompId, setTabTouchCompId] = useState(
        season.config?.tabTouchCompId || '',
    )
    const [ingestionEditable, setIngestionEditable] = useState(false)
    const [tabTouchEditable, setTabTouchEditable] = useState(false)

    const submit = async (
        config: Partial<SeasonConfig>,
        callback: (arg0: boolean) => void,
    ) => {
        if (season.config) {
            await configure({
                season,
                config: {
                    ...season.config,
                    ...config,
                },
            })
            await refetch()
            callback(false)
        } else {
            callback(false)
        }
    }

    return (
        <Box>
            <Text fontSize={'x-large'}>Competition Data IDs</Text>
            <Box display="flex" gap="5">
                <CardComponent
                    title="ChampionData Comp Id"
                    content={
                        <Box display="flex" flexDir="row" alignItems="center">
                            {ingestionEditable ? (
                                <Input
                                    onChange={(e) =>
                                        setIngestionCompId(e.target.value)
                                    }
                                    value={ingestionCompId}
                                    placeholder="Ingestion Comp Id"
                                />
                            ) : season.config?.ingestionCompId ? (
                                <Text fontWeight="bold">
                                    {season.config?.ingestionCompId}
                                </Text>
                            ) : (
                                <Text opacity="0.7" fontStyle="italic">
                                    No comp id
                                </Text>
                            )}
                        </Box>
                    }
                    action={
                        <Flex flexDirection="column" gap="1">
                            <Button
                                colorScheme={
                                    ingestionEditable ? 'gray' : 'blue'
                                }
                                leftIcon={<EditIcon />}
                                onClick={() =>
                                    setIngestionEditable(!ingestionEditable)
                                }
                            >
                                {ingestionEditable ? 'Cancel' : 'Edit'}
                            </Button>
                            {ingestionEditable ? (
                                <Button
                                    colorScheme="blue"
                                    leftIcon={<FaSave />}
                                    onClick={() =>
                                        submit(
                                            {
                                                ingestionCompId: ingestionCompId,
                                            },
                                            setIngestionEditable,
                                        )
                                    }
                                >
                                    Save
                                </Button>
                            ) : null}
                        </Flex>
                    }
                />
                <CardComponent
                    title="TabTouch Comp Id"
                    content={
                        <Box display="flex" flexDir="row" alignItems="center">
                            {tabTouchEditable ? (
                                <Input
                                    onChange={(e) =>
                                        setTabTouchCompId(e.target.value)
                                    }
                                    value={tabTouchCompId}
                                    placeholder="TabTouch Comp Id"
                                />
                            ) : season.config?.tabTouchCompId ? (
                                <Text fontWeight="bold">
                                    {season.config?.tabTouchCompId}
                                </Text>
                            ) : (
                                <Text opacity="0.7" fontStyle="italic">
                                    No comp id
                                </Text>
                            )}
                        </Box>
                    }
                    action={
                        <Flex flexDirection="column" gap="1">
                            <Button
                                colorScheme={tabTouchEditable ? 'gray' : 'blue'}
                                leftIcon={<EditIcon />}
                                onClick={() =>
                                    setTabTouchEditable(!tabTouchEditable)
                                }
                            >
                                {tabTouchEditable ? 'Cancel' : 'Edit'}
                            </Button>
                            {tabTouchEditable ? (
                                <Button
                                    colorScheme="blue"
                                    leftIcon={<FaSave />}
                                    onClick={() =>
                                        submit(
                                            {
                                                tabTouchCompId: tabTouchCompId,
                                            },
                                            setTabTouchEditable,
                                        )
                                    }
                                >
                                    Save
                                </Button>
                            ) : null}
                        </Flex>
                    }
                />
            </Box>
        </Box>
    )
}
