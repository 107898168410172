export const environment = {
    production: process.env.NX_PRODUCTION === 'true',
    baseUrl: process.env.NX_SITE_URL,
    apiGatewayUrl: process.env.NX_API_GATEWAY_URL,
    clientId: process.env.NX_AUTH_CLIENT_ID,
    idp: process.env.NX_AUTH_ENDPOINT,
    metadataDocument: process.env.NX_AUTH_METADATA_DOCUMENT,
    scope: process.env.NX_AUTH_SCOPE,
    frontendUrl: process.env.NX_FRONTEND_URL,
    thegameApiUrl: process.env.NX_THEGAME_API_URL,
    userAuthEndpoint: process.env.NX_USER_AUTH_ENDPOINT,
    publicBucketName: process.env.NX_PUBLIC_BUCKET_NAME,
}
