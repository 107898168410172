import React, { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { SidebarWindow, TabComponents } from './components/SidebarWindow'
import { Sponsors } from './sections/SponsorsSection'
import {
    FaDollarSign,
    FaLink,
    FaMobileAlt,
    FaRegBell,
    FaRegComment,
    FaRegImage,
    FaTrophy,
} from 'react-icons/fa'
import { BannerMessageSection } from './sections/BannerMessageSection'
import { AppVersionSection } from './sections/AppVersionSection'
import { PushNotificationsSection } from './sections/PushNotificationsSection'
import { DeepLinkSection } from './sections/DeepLinkSection'
import { CustomCompetitionSection } from './sections/CustomCompetitionSection'
import { permissions, useAuthenticatedUser } from './Authentication'
import { useHistory } from 'react-router-dom'
import { OfficialPartnerUpload } from './sections/OfficialPartnerUpload'
import { HomeTextSection } from './sections/HomeTextSection'
import { PrizeImageSection } from './sections/PrizeImageSection'
import { FeaturedImagesSection } from './sections/FeaturesImagesSection'

function FormContentWrapper({ children }) {
    return (
        <Flex flexDirection={'column'} gap="8">
            {children}
        </Flex>
    )
}

export function ConfigAndToolsForm({ tab }: { tab?: string }) {
    const history = useHistory()
    const user = useAuthenticatedUser()
    const isAdmin = !!user?.hasPermission(permissions.admin)

    const tabs: TabComponents[] = [
        {
            link: 'content',
            name: 'Log-in Page Content',
            icon: <FaRegImage size="24" />,
            content: (
                <FormContentWrapper>
                    <HomeTextSection />
                    <PrizeImageSection />
                    <FeaturedImagesSection />
                </FormContentWrapper>
            ),
        },
        {
            link: 'sponsors',
            name: 'Sponsors',
            icon: <FaDollarSign size="24" />,
            content: (
                <FormContentWrapper>
                    <OfficialPartnerUpload />
                    <Sponsors />
                </FormContentWrapper>
            ),
        },
        {
            link: 'custom-comp',
            name: 'Custom Competitions',
            icon: <FaTrophy size="24" />,
            content: <CustomCompetitionSection />,
        },
        {
            link: 'message',
            name: 'Message Banner',
            icon: <FaRegComment size="24" />,
            content: <BannerMessageSection />,
        },
        {
            link: 'notifications',
            name: 'Push Notifications',
            icon: <FaRegBell size="24" />,
            content: <PushNotificationsSection />,
        },
        {
            link: 'deep-links',
            name: 'Deep Links',
            icon: <FaLink size="24" />,
            content: <DeepLinkSection />,
        },
    ]

    if (isAdmin) {
        tabs.push({
            link: 'app-versions',
            name: 'App Versions',
            icon: <FaMobileAlt size="24" />,
            content: <AppVersionSection />,
        })
    }

    const [activeTab, setActiveTab] = useState(
        tabs.find((t) => t.link === tab)?.link ?? 'content',
    )

    useEffect(() => {
        tab && setActiveTab(tab)
    }, [tab])

    function handleSetActive(t: string) {
        if (activeTab !== t) {
            setActiveTab(t)
            history.push(`/config/${t}`)
        }
    }

    return (
        <Box mb="8" px="10" maxW={'1900px'} width="full" marginX="auto">
            <SidebarWindow
                active={activeTab}
                setActive={handleSetActive}
                tabs={tabs}
            ></SidebarWindow>
        </Box>
    )
}
