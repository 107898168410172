import React, { useState } from 'react'
import {
    Button,
    Divider,
    Input,
    Box,
    Text,
    Select,
    Code,
    Flex,
    useToast,
} from '@chakra-ui/react'
import { useGetGames } from '../data'

export function DeepLinkSection() {
    const { data: allGames } = useGetGames()
    const games = allGames?.filter((x) => x.published)

    const toast = useToast()
    const [selectedPage, setSelectedPage] = useState('Home')
    const [selectedSportSeason, setSelectedSportSeason] = useState('')
    const [deepLink, setDeepLink] = useState('')
    const [competitionId, setCompetitionId] = useState('')

    const isValidDeepLink =
        (competitionId !== '' || selectedPage !== 'JoinCompetition') &&
        selectedSportSeason !== ''

    function buildDeepLink(page, sportSeason) {
        const [sport, season] = sportSeason.split(' ')

        const makeLink = (web: string, mobile: string) =>
            `https://thegame.page.link/?link=https://thegame.com.au${web}${mobile}&apn=com.thegameapp&isi=1638897595&ibi=com.thegameapp&cid=7869091508201055549&_icp=1`

        switch (page) {
            case 'Home':
                return makeLink('', `?page%3Dhome%26sport%3D${sport}`)
            case 'Tipping':
                return makeLink(
                    `/${sport}/tipping`,
                    `?page%3Dtipping%26sport%3D${sport}`,
                )
            case 'Fixtures':
                return makeLink('', `?page%3Dfixtures%26sport%3D${sport}`)
            case 'Ladder':
                return makeLink(
                    `/${sport}/ladder`,
                    `?page%3Dladder%26sport%3D${sport}`,
                )
            case 'Competitions':
                return makeLink(
                    `/${sport}/tipping/competitions`,
                    `?page%3Dcompetitions%26sport%3D${sport}`,
                )
            case 'OfficialCompetition':
                return makeLink(
                    `/${sport}/tipping/leaderboard`,
                    `?page%3DofficialCompetition%26sport%3D${sport}%26season%3D${season}`,
                )
            case 'News':
                return makeLink('', '?page%3Dnews')
            case 'JoinCompetition':
                return makeLink(
                    `/c/${competitionId}`,
                    `?action%3Djoin%26id%${competitionId}`,
                )
            default:
                return makeLink('', '?page%3Dhome')
        }
    }

    return (
        <Box>
            <Box>
                <Flex direction="column">
                    <Text fontWeight="bold" fontSize="xx-large">
                        Build Deep Link
                    </Text>
                    <Text fontWeight="normal" fontSize="medium">
                        This tool will build a link that can take a user to a
                        specific page of the mobile application. If the app is
                        not installed it will point them to the
                        AppStore/PlayStore.
                    </Text>
                    <br />
                    <Text fontWeight="normal" fontSize="medium">
                        If opened via a desktop computer the link will fallback
                        to the web equivalent (if the relevant page exists)
                    </Text>
                </Flex>
            </Box>
            <Divider margin="16px 0px" />
            <Box maxW="600px">
                <Text mb="2" mt="4">
                    Page
                </Text>
                <Select
                    placeholder="Select page..."
                    onChange={(e) => setSelectedPage(e.target.value)}
                    value={selectedPage}
                >
                    <option value="Home">Home</option>
                    <option value="Tipping">Tipping</option>
                    <option value="Fixtures">Fixtures</option>
                    <option value="Ladder">Ladder</option>
                    <option value="Competitions">Competitions</option>
                    <option value="OfficialCompetition">
                        Official Competition
                    </option>
                    <option value="News">News</option>
                    <option value="JoinCompetition">Join Competition</option>
                </Select>
                <Text mb="2" mt="4">
                    Sport & Season
                </Text>
                <Select
                    placeholder="Select sport/season..."
                    onChange={(e) => setSelectedSportSeason(e.target.value)}
                    value={selectedSportSeason}
                >
                    {games?.map((game) => (
                        <option
                            key={game.sport + ' ' + game.season}
                            value={game.sport + ' ' + game.season}
                        >
                            {game.sport + ' ' + game.season}
                        </option>
                    ))}
                </Select>
                {selectedPage === 'JoinCompetition' && (
                    <>
                        <Text mb="2" mt="4">
                            Competition Id
                        </Text>
                        <Input
                            title="CompetitionId"
                            value={competitionId}
                            onChange={(e) => setCompetitionId(e.target.value)}
                        />
                    </>
                )}
            </Box>
            <Divider margin="16px 0px" />
            {deepLink !== '' && (
                <Flex alignItems="center" mb="4">
                    <Code padding="2" width="80%">
                        {deepLink}
                    </Code>
                    <Button
                        ml="20px"
                        onClick={() => {
                            navigator.clipboard.writeText(deepLink)
                            toast({
                                title: 'Deep link copied',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            })
                        }}
                    >
                        Copy
                    </Button>
                </Flex>
            )}
            <Button
                // colorScheme="blue"
                // marginTop="4"
                disabled={!isValidDeepLink}
                onClick={() => {
                    setDeepLink(
                        buildDeepLink(selectedPage, selectedSportSeason),
                    )
                }}
            >
                Build Link
            </Button>
        </Box>
    )
}
