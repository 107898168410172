import React from 'react'
import { Box, Text, Link, Button, useToast } from '@chakra-ui/react'
import { CheckCircleIcon, WarningIcon, AddIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { FileControl } from '../components/FileControl'
import { environment } from '../../environments/environment'
import { isLocalEnv } from '../helpers/is-local-env'
import { CardComponent } from '../components/CardComponent'
import { ManageSeasonBaseProps } from './ManageSeason'
import { LaunchType } from '../data'

export function DocumentsAndPrizing({
    season,
    refetch,
}: ManageSeasonBaseProps) {
    const history = useHistory()
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const [configureSeason] = useConfigureSeason()
    const toast = useToast()

    const handleFile = async (file: File) => {
        const filename = `${season.season}-${
            season.sport
        }-terms-${Date.now()}.pdf`
        const contentType = file.type

        if (isLocalEnv()) {
            await uploadFile({
                file,
                filename,
            })
            const config = {
                prizing: [],
                sendGridId: '',
                ingestionCompId: '',
                launchType: 'Soft Launch' as LaunchType,
                ...season.config,
                termsAndConditions: filename,
            }

            await configureSeason({
                season,
                config,
            })

            await refetch()
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: file,
                })

                if (result.status === 200) {
                    const config = {
                        prizing: [],
                        sendGridId: '',
                        ingestionCompId: '',
                        ...season.config,
                        termsAndConditions: filename,
                    }

                    const res = await configureSeason({
                        season,
                        config,
                    })

                    if (res === 200) {
                        await refetch()
                    }
                } else {
                    toast({
                        title: 'Oh no, something went wrong uploading file..',
                        description:
                            'Please check the console and contact the dev team. You might need to manually put the file into the pubic_assets/ S3 bucket',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch {
                toast({
                    title: 'Couldnt create the presigned URL...',
                    description:
                        'Please check the console and contact the dev team.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }

    const termsLink =
        season.config && season.config.termsAndConditions !== ''
            ? `${
                  isLocalEnv()
                      ? environment.apiGatewayUrl
                      : environment.frontendUrl
              }/public-assets/${season.config.termsAndConditions}`
            : undefined

    return (
        <Box>
            <Text fontSize={'x-large'}>Documents & Prizing</Text>
            <Box display="flex" gap="5">
                <CardComponent
                    title="Terms and Conditions"
                    content={
                        termsLink ? (
                            <Box
                                display="flex"
                                flexDir="row"
                                alignItems="center"
                            >
                                <CheckCircleIcon color="green" mr="1" />

                                <Link
                                    href={termsLink}
                                    target="_blank"
                                    opacity="0.7"
                                >
                                    {season.config?.termsAndConditions}
                                </Link>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                flexDir="row"
                                alignItems="center"
                            >
                                <WarningIcon color="#D22828" mr="1" />

                                <Text color="#5C5C5C">None Configured</Text>
                            </Box>
                        )
                    }
                    action={
                        <FileControl
                            buttonLabel="Upload"
                            accept=".pdf"
                            onSelect={handleFile}
                        />
                    }
                />

                <CardComponent
                    title="Prizing"
                    content={
                        season.config?.prizing &&
                        season.config.prizing.length >= 1 ? (
                            <Box
                                display="flex"
                                flexDir="row"
                                alignItems="center"
                            >
                                <CheckCircleIcon color="green" mr="1" />

                                <Text opacity="0.7">
                                    {season.config?.prizing.length} Prizes
                                    Configured
                                </Text>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                flexDir="row"
                                alignItems="center"
                            >
                                <WarningIcon color="#D22828" mr="1" />

                                <Text opacity="0.7">None Configured</Text>
                            </Box>
                        )
                    }
                    action={
                        <Button
                            colorScheme="blue"
                            leftIcon={<AddIcon />}
                            onClick={() =>
                                history.push(
                                    `/seasons/${season.sport}_${season.season}/prizing`,
                                )
                            }
                        >
                            Configure
                        </Button>
                    }
                />
            </Box>
        </Box>
    )
}
