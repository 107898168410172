import React, { useEffect, useState } from 'react'
import { Tabs, Tab, TabList, useToast } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'

export function Menu({
    hasCommercialAccess,
}: {
    hasCommercialAccess: boolean
}) {
    const history = useHistory()
    const location = useLocation()
    const toast = useToast()

    useEffect(() => {
        if (location.pathname.startsWith('/seasons')) {
            if (!hasCommercialAccess) return
            setTabIndex(0)
        } else if (location.pathname.startsWith('/search')) {
            setTabIndex(1)
        } else if (location.pathname.startsWith('/config')) {
            if (!hasCommercialAccess) return
            setTabIndex(2)
        } else {
            setTabIndex(1)
        }
    }, [location.pathname, hasCommercialAccess])

    const [tabIndex, setTabIndex] = useState(1)

    const handleTabsChange = (index: number) => {
        if (!hasCommercialAccess) return
        setTabIndex(index)
    }

    function fullAccessRedirect(path: string) {
        if (location.pathname !== path) {
            hasCommercialAccess
                ? history.push(path)
                : toast({
                      title: 'Invalid access',
                      description:
                          "You don't have access rights for this tab. If you think you should, please contact the dev team.",
                      status: 'warning',
                      duration: 5000,
                      isClosable: true,
                  })
        }
    }

    return (
        <Tabs index={tabIndex} onChange={handleTabsChange} mb={4}>
            <TabList pl="2">
                <Tab
                    onClick={() => fullAccessRedirect('/seasons')}
                    opacity={hasCommercialAccess ? '1' : '0.5'}
                >
                    Manage Seasons
                </Tab>
                <Tab onClick={() => history.push('/search')}>Users</Tab>
                <Tab
                    onClick={() => fullAccessRedirect('/config/content')}
                    opacity={hasCommercialAccess ? '1' : '0.5'}
                >
                    Config & Tools
                </Tab>
            </TabList>
        </Tabs>
    )
}
