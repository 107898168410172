import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalFooter,
} from '@chakra-ui/react'
import React from 'react'

type ModalProps = {
    size: string
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
    footer?: React.ReactNode
}

export function StyledModal({
    size,
    isOpen,
    onClose,
    children,
    footer,
}: ModalProps) {
    return (
        <Modal size={size} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>{children}</ModalBody>
                {footer && (
                    <ModalFooter display="flex" justifyContent="center">
                        {footer}
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    )
}
