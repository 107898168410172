import { Button, Flex, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdCancel, MdEdit, MdUpload } from 'react-icons/md'
import { HomeText } from '../sections/HomeTextSection'
import DOMPurify from 'dompurify'

export function HomeTextWidget({
    text,
    handleSaveText,
    loading,
}: {
    text: HomeText
    handleSaveText: (arg0: HomeText) => {}
    loading: boolean
}) {
    const { title, subtitle } = text
    const [editable, setEditable] = useState(false)
    const [newTitle, setNewTitle] = useState(title || '')
    const [newSubtitle, setNewSubtitle] = useState(subtitle || '')

    function handleClickEdit() {
        setNewTitle(title || '')
        setNewSubtitle(subtitle || '')

        setEditable(!editable)
    }

    async function handleClickSave() {
        await handleSaveText({
            title: DOMPurify.sanitize(newTitle),
            subtitle: DOMPurify.sanitize(newSubtitle),
        })
        setEditable(false)
    }

    function textToHtml(text: string) {
        const htmlDirty = text
            ? text.replaceAll('\n', ' <br />').toUpperCase()
            : ''
        const htmlClean = DOMPurify.sanitize(htmlDirty)
        return htmlClean
    }

    return (
        <Flex
            direction="row"
            justifyContent="space-between"
            padding={4}
            border={`1px #65707d77 solid`}
            transition="0.5s"
            borderRadius={8}
            maxWidth="1000"
            gap={4}
            opacity={loading ? '0.6' : '1'}
        >
            <Flex flexDirection="column" gap={2} flex="1">
                <>
                    <div>
                        <Text opacity={0.7} fontSize="xs">
                            Title
                        </Text>
                        {editable ? (
                            <Textarea
                                placeholder={title}
                                size="sm"
                                value={newTitle}
                                onChange={(e) => setNewTitle(e.target.value)}
                            />
                        ) : (
                            <Text
                                lineHeight={1.2}
                                wordBreak="break-word"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: textToHtml(text.title),
                                }}
                            ></Text>
                        )}
                    </div>
                    <div>
                        <Text opacity={0.7} fontSize="xs">
                            Subtitle
                            <b style={{ color: '#ff6912' }}>
                                {' '}
                                (Note: This text is currently overwritten by a
                                hardcoded image! Changes to the subtitle won't
                                do anything.)
                            </b>
                        </Text>
                        {editable ? (
                            <Textarea
                                placeholder={subtitle}
                                size="sm"
                                value={newSubtitle}
                                onChange={(e) => setNewSubtitle(e.target.value)}
                            />
                        ) : (
                            <Flex gap="12">
                                <Text
                                    lineHeight={1.2}
                                    wordBreak="break-word"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: textToHtml(text.subtitle),
                                    }}
                                ></Text>
                            </Flex>
                        )}
                    </div>
                </>
            </Flex>
            <Flex flexDirection="column" gap="2" justifyContent="center">
                <Button
                    onClick={() => handleClickEdit()}
                    leftIcon={editable ? <MdCancel /> : <MdEdit />}
                    disabled={loading}
                >
                    {editable ? 'Cancel' : 'Edit'}
                </Button>
                {editable && (
                    <Flex flexDirection="row" gap="2">
                        <Button
                            onClick={() => handleClickSave()}
                            colorScheme="blue"
                            leftIcon={<MdUpload />}
                            disabled={loading}
                        >
                            Publish
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
