import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Box, Button, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import {
    BannerMessageComponents,
    BannerMessageWidget,
} from '../components/BannerMessageWidget'
import {
    fetchJsonData,
    writeJsonToPublicAssets,
} from '../helpers/public-json-io'

const filename = 'banner-message-data.json'

export function BannerMessageSection() {
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const { oidcUser } = useReactOidc()
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const toast = useToast()
    const [message, setMessage] = useState<BannerMessageComponents | undefined>(
        undefined,
    )
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
        fetchMessageData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheBuster, oidcUser])

    async function handleSaveMessage(newMessage: BannerMessageComponents) {
        const emptyMessage = !newMessage.body || !newMessage.title
        if (emptyMessage) {
            toast({
                title:
                    'Cannot publish an empty message. Ensure both a title and body are set.',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        const noChanges =
            newMessage.body === message?.body &&
            newMessage.title === message?.title &&
            newMessage.sport === message?.sport
        if (noChanges) {
            toast({
                title: 'No changes detected, not publishing',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        // @ts-ignore
        const newId = crypto.randomUUID().toString()
        const newDate = new Date().toLocaleString('en-AU', {
            dateStyle: 'short',
            timeStyle: 'short',
        })
        handleUpdateMessage({ ...newMessage, id: newId, date: newDate })
    }

    async function handleDeleteMessage() {
        handleUpdateMessage({
            id: '',
            title: '',
            body: '',
            sport: '',
            date: '',
        })
    }

    async function handleUpdateMessage(newMessage: BannerMessageComponents) {
        setLoading(true)
        try {
            await writeJsonToPublicAssets(
                filename,
                newMessage,
                uploadFile,
                getSignedUrl,
            )
            setCachebuster(Date.now())
        } catch (e: any) {
            toast({
                title: 'Oh no something went wrong!',
                description:
                    'There was an error updating the message - please check the console and contact the dev team if the problem persists',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setLoading(false)
        }
    }

    async function fetchMessageData() {
        setLoading(true)
        try {
            const data: any = await fetchJsonData(
                filename,
                cacheBuster.toString(),
            )
            console.debug('Fetched message data.', data)
            setMessage(data)
        } catch (e: any) {
            console.error("Couldn't retrieve message data.", e)
            setError("Couldn't retrieve message data.")
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <Text fontSize={'xx-large'} fontWeight="bold">
                Banner Message
            </Text>
            <Text paddingBottom={4}>
                This is displayed as a banner 'news strap' to users. Once they
                close it they will not see this message again.
                <b>
                    {' '}
                    If you publish a new message it will appear for all valid
                    users again.
                </b>{' '}
                If you assign a sport to the message, the message will be the
                colour of the sport, and will only appear when (1) that sport
                has a published season and (2) the user is not already
                registered for that season. If you assign 'No sport', it will
                appear to all users.
            </Text>
            {error ? (
                <Box>
                    <Text color="red">{`Error: ${error}`}</Text>
                    <Button onClick={fetchMessageData}>Try Again</Button>
                </Box>
            ) : (
                message && (
                    <BannerMessageWidget
                        message={message}
                        handleSaveMessage={handleSaveMessage}
                        handleDeleteMessage={handleDeleteMessage}
                        loading={loading}
                    />
                )
            )}
        </Box>
    )
}
