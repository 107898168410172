import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Divider,
    GridItem,
    Heading,
    Input,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    Textarea,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { Season, useGetGames, useSendPersonalNotification } from '../data'

export function PushNotificationsSection() {
    const [audience, setAudience] = useState<string>('')
    const [notificationBody, setNotificationBody] = useState<string>('')
    const [deliveryType, setDeliveryType] = useState<string>('Both')
    const [notificationType, setNotificationType] = useState<string>('Tipping')
    const [notificationTitle, setNotificationTitle] = useState<string>('')
    const [selectedGame, setSelectedGame] = useState<Season>()
    const [index, setIndex] = useState<number | undefined>()
    const [userSubs, setUserSubs] = useState<string>('')
    const [isConfirmationOpen, setConfirmationOpen] = useState(false)

    const validNotificationType = notificationType === 'Tipping'
    const isValid =
        (validNotificationType &&
            notificationBody &&
            notificationTitle &&
            deliveryType &&
            audience === 'All') ||
        (validNotificationType &&
            notificationBody &&
            notificationTitle &&
            deliveryType &&
            audience !== 'All' &&
            selectedGame)
    const [sendNotification] = useSendPersonalNotification()
    const { data: allGames } = useGetGames()
    const games = allGames?.filter((x) => x.published)
    const cancelRef = useRef<HTMLButtonElement>()

    const onSendPressed = () => {
        if (isValid) {
            const body = JSON.stringify({
                body: notificationBody,
                title: notificationTitle,
                sport: selectedGame?.sport || '',
                type: notificationType,
                seasonName: selectedGame?.season || '',
                round: selectedGame?.currentRound.toString(),
                audience,
                userSubs: userSubs
                    .split(',')
                    .filter((sub) => sub.trim() !== ''),
                deliveryType,
            })
            sendNotification(body)
            handleClose()
        }
    }

    function handleClose() {
        setSelectedGame(undefined)
        setIndex(undefined)
        setNotificationBody('')
        setNotificationTitle('')
        setAudience('')
        setUserSubs('')
        setConfirmationOpen(false)
    }

    return (
        <>
            <Box>
                <Box>
                    <Text fontSize={'xx-large'} fontWeight="bold">
                        Create Push Notification
                    </Text>
                    <Text>
                        This tool will send a notification to the users, based
                        on the input you give it.
                    </Text>
                </Box>
                <Divider margin="16px 0px" />
                <Heading fontSize="Small" fontWeight="normal">
                    Who are we messaging
                </Heading>
                <RadioGroup value={audience} onChange={setAudience}>
                    <Stack direction="row">
                        <Radio size="md" value="All">
                            All Members
                        </Radio>
                        <Radio size="md" value="Registered">
                            Registered Members
                        </Radio>
                        <Radio size="md" value="Targeted">
                            Targeted
                        </Radio>
                        <Radio size="md" value="NotRegistered">
                            Not Registered
                        </Radio>
                    </Stack>
                </RadioGroup>
                <Divider margin="16px 0px" />
                <Heading fontSize="Small" fontWeight="normal">
                    Category
                </Heading>
                <RadioGroup
                    value={notificationType}
                    onChange={setNotificationType}
                >
                    <Stack direction="row">
                        <Radio value="Tipping">Tipping</Radio>
                    </Stack>
                </RadioGroup>
                <Divider margin="16px 0px" />
                <Heading fontSize="Small" fontWeight="normal">
                    Type of delivery
                </Heading>
                <RadioGroup value={deliveryType} onChange={setDeliveryType}>
                    <Stack direction="row">
                        <Radio value="Both">
                            Send both push and personal notification
                        </Radio>
                        <Radio value="Personal">
                            Send personal notification only
                        </Radio>
                    </Stack>
                </RadioGroup>
                <Divider margin="16px 0px" />
                {games && audience !== 'All' && (
                    <Box>
                        <Select
                            placeholder="Select sport/season..."
                            value={index}
                            onChange={(e) => {
                                setIndex(parseInt(e.target.value))
                                setSelectedGame(games[e.target.value])
                            }}
                        >
                            {games.map((game, idx) => (
                                <option key={idx} value={idx}>
                                    {game.sport + ' ' + game.season}
                                </option>
                            ))}
                        </Select>
                    </Box>
                )}
                <Divider margin="16px 0px" />
                <Box>
                    <Input
                        name="body"
                        type="text"
                        placeholder="Enter notification Title"
                        value={notificationTitle}
                        size="md"
                        onChange={(event) =>
                            setNotificationTitle(event.target.value)
                        }
                    />
                    <Textarea
                        margin="16px 0px"
                        name="body"
                        placeholder="Enter notification Body"
                        value={notificationBody}
                        size="md"
                        onChange={(event) =>
                            setNotificationBody(event.target.value)
                        }
                    />
                    {audience === 'Targeted' && (
                        <Textarea
                            margin="16px 0px"
                            name="body"
                            placeholder="Enter User Subs (Separated by Commas)"
                            value={userSubs}
                            size="md"
                            onChange={(event) =>
                                setUserSubs(event.target.value)
                            }
                        />
                    )}
                </Box>
                <Box display="flex">
                    <GridItem
                        as={Button}
                        colorScheme="gray"
                        onClick={() => {
                            setConfirmationOpen(true)
                        }}
                        disabled={!isValid}
                    >
                        Send
                    </GridItem>
                </Box>
            </Box>
            <AlertDialog
                isOpen={isConfirmationOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setConfirmationOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Send Push Notification
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to send to {audience} for{' '}
                            {selectedGame?.sport}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    setConfirmationOpen(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={onSendPressed}
                                ml={3}
                            >
                                Send
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
