import { useReactOidc } from '@axa-fr/react-oidc-context'
import { User, UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import { environment } from '../environments/environment'

export const AuthConfiguration: UserManagerSettings = {
    client_id: environment.clientId,
    redirect_uri: `${environment.baseUrl}/authentication/callback`,
    response_type: 'code',
    post_logout_redirect_uri: environment.baseUrl,
    scope: environment.scope,
    authority: environment.idp,
    silent_redirect_uri: `${environment.baseUrl}/authentication/silent_callback`,
    automaticSilentRenew: true,
    response_mode: 'query',
    userStore: new WebStorageStateStore({ store: localStorage }),
    staleStateAge: 43200, // 12 hours
    metadataUrl: environment.metadataDocument,
}

function parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
    )
    return JSON.parse(jsonPayload)
}

export const permissions = {
    callCenter: 'call-center',
    admin: 'admin',
    commercial: 'commercial',
} as const

export type Permission = typeof permissions[keyof typeof permissions]

export interface AuthenticatedUser extends User {
    hasPermission: (schema: Permission) => boolean
    loggedIn: boolean
}

export function useAuthenticatedUser(): AuthenticatedUser | null {
    const { oidcUser } = useReactOidc()

    if (!oidcUser) {
        return null
    }

    const accessToken = parseJwt(oidcUser.access_token)

    return {
        ...oidcUser,
        loggedIn: !oidcUser.expired,
        toStorageString: oidcUser.toStorageString,
        hasPermission: (schema: Permission) => {
            return accessToken.roles && accessToken.roles.includes(schema)
        },
    }
}
