import { Season, SeasonState } from '../data'

export function getSeasonState(season: Season): SeasonState | undefined {
    const published = season.published
    const status = season.status
    const launchType = season.config?.launchType

    if (!published) {
        // not published
        if (status === 'not-started') {
            return 'draft'
        } else {
            return 'archived'
        }
    } else if (launchType === 'Soft Launch') {
        // published, in 'Soft Launch'
        return 'published'
    } else if (status === 'not-started') {
        // published, in 'Full Launch'
        return 'early-bird'
    } else if (status === 'in-progress') {
        // published, 'Full Launch', 'in-progress'
        return 'in-season'
    } else if (status === 'completed') {
        // published, 'Full Launch', 'completed'
        return 'completed'
    } else {
        return undefined
    }
}
