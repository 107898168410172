import React from 'react'
import { Box, IconButton, useColorMode, Button } from '@chakra-ui/react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { useAuthenticatedUser } from './Authentication'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

export function Header() {
    const { toggleColorMode, colorMode } = useColorMode()
    const { login, logout } = useReactOidc()
    const user = useAuthenticatedUser()
    const loggedIn = user?.loggedIn

    return (
        <Box style={{ position: 'absolute', right: 30 }}>
            <IconButton
                aria-label="Toggle Colour Theme"
                onClick={toggleColorMode}
                mr="4"
                size="sm"
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            />
            {loggedIn && (
                <Button type="button" onClick={() => logout()} size="sm">
                    Log out
                </Button>
            )}
            {!loggedIn && (
                <Button type="button" onClick={() => login()} size="sm">
                    Log in
                </Button>
            )}
        </Box>
    )
}
