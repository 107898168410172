import {
    Badge,
    Box,
    Collapse,
    Flex,
    GridItem,
    List,
    ListItem,
    Text,
    useColorMode,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaChevronDown, FaInfoCircle } from 'react-icons/fa'
import { SeasonState, seasonStateColorMap } from '../data'

export function TrackHeading({
    state,
    active,
    tooltip,
}: {
    state: SeasonState
    active: boolean
    tooltip?: {
        heading?: string
        subheading?: string
        points?: string[]
    }
}) {
    const [hovered, setHovered] = useState(false)
    const { colorMode } = useColorMode()

    function handleMouseOver(hover: boolean) {
        if (hover !== hovered) {
            setHovered(hover)
        }
    }

    const tooltipColor = colorMode === 'light' ? '#d7dee5' : '#424d58'

    return (
        <GridItem>
            <Flex py={'5'} justify="center" align="center" gap="3">
                <Badge
                    height="fit-content"
                    colorScheme={active ? seasonStateColorMap[state] : 'gray'}
                    width="40"
                    textAlign="center"
                    py="1"
                    fontSize="sm"
                    textTransform="uppercase"
                    borderRadius="lg"
                    border={active ? '2px solid' : 'none'}
                >
                    {state}
                </Badge>
                {tooltip ? (
                    <Box
                        cursor="help"
                        onMouseEnter={() => handleMouseOver(true)}
                        onMouseLeave={() => handleMouseOver(false)}
                        position="relative"
                    >
                        <FaInfoCircle opacity={0.3} size="20" />

                        <Box
                            position="absolute"
                            minW="540px"
                            left="10"
                            top="-4"
                            p="4"
                            backgroundColor={tooltipColor}
                            borderRadius="md"
                            transitionDuration="0.2s"
                            pointerEvents="none"
                            opacity={hovered ? '1' : '0'}
                            zIndex="10"
                            filter="drop-shadow(5px 5px 12px #0000003d)"
                        >
                            <Box
                                width="0"
                                height="0"
                                borderTop="10px solid transparent"
                                borderRight={`18px solid ${tooltipColor}`}
                                borderBottom="10px solid transparent"
                                position="absolute"
                                top="4"
                                left="-4"
                            ></Box>
                            <Flex flexDirection={'column'} gap="0">
                                {active ? (
                                    <Text
                                        mb="1"
                                        fontStyle="italic"
                                        opacity="0.7"
                                    >
                                        The season is currently in {state}!
                                    </Text>
                                ) : null}
                                <Text fontWeight="bold">{tooltip.heading}</Text>
                                <Text>{tooltip.subheading}</Text>
                                {tooltip.points?.length ? (
                                    <List listStyleType="disc" pl="4">
                                        {tooltip.points?.map((p, i) => {
                                            return (
                                                <ListItem key={i}>{p}</ListItem>
                                            )
                                        })}
                                    </List>
                                ) : null}
                            </Flex>
                        </Box>
                    </Box>
                ) : null}
            </Flex>
        </GridItem>
    )
}

export function TrackContent({ children }) {
    return (
        <GridItem
            border={`1px solid #65707d77`}
            // borderBottom="none"
            // borderTop="none"
            borderRadius="md"
        >
            <Flex
                flexDirection="column"
                alignItems="flex-start"
                px="7"
                py={'5'}
                gap="8"
            >
                {children}
            </Flex>
        </GridItem>
    )
}

export function ReminderList({
    heading,
    points,
    defaultClosed,
}: {
    heading: string
    points?: string[]
    defaultClosed?: boolean
}) {
    const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
        defaultIsOpen: !defaultClosed,
    })

    useEffect(() => {
        if (!defaultClosed) {
            onOpen()
        } else {
            onClose()
        }
    }, [defaultClosed])

    return (
        <Flex flexDirection={'column'} gap="0" opacity="0.6">
            <Flex
                align="center"
                gap="2"
                cursor={points ? 'pointer' : 'default'}
                onClick={onToggle}
            >
                {points ? (
                    <FaChevronDown
                        size="16"
                        style={{
                            transform: isOpen ? '' : 'rotate(-90deg)',
                            transitionDuration: '0.2s',
                        }}
                    />
                ) : null}
                <Text>{heading}</Text>
            </Flex>
            {points?.length ? (
                <Collapse in={isOpen} animateOpacity>
                    <List listStyleType="disc" pl="10">
                        {points?.map((p, i) => {
                            return <ListItem key={i}>{p}</ListItem>
                        })}
                    </List>
                </Collapse>
            ) : null}
        </Flex>
    )
}
