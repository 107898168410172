import { Venue, Venues } from '../api-interfaces'

import * as z from 'zod'
import { MatchStatus } from '../lifecycle'

export const venueNameMapping: Record<string, Venue> = {
    'Adelaide Oval': Venues.AO,
    Gabba: Venues.GABBA,
    'Brisbane Cricket Ground': Venues.GABBA,
    'GMHBA Stadium': Venues.GMHBA,
    'Marvel Stadium': Venues.MARVEL,
    'Docklands Stadium': Venues.MARVEL,
    MCG: Venues.MCG,
    'Melbourne Cricket Ground': Venues.MCG,
    'Metricon Stadium': Venues.METRICON,
    'Optus Stadium': Venues.OPTUS,
    'GIANTS Stadium': Venues.GIANTS,
    SCG: Venues.SCG,
    'Sydney Cricket Ground': Venues.SCG,
    'TIO Stadium': Venues.TIO,
    'TIO Traeger Park': Venues.TRAEGER,
    "Cazaly's Stadium": Venues.CAZALYS,
    'Cazalys Stadium': Venues.CAZALYS,
    'Manuka Oval': Venues.MANUKA,
    'Mars Stadium': Venues.MARS,
    'University of Tasmania Stadium': Venues.TASUNIVERSITY,
    'UTAS Stadium': Venues.TASUNIVERSITY,

    'Blundstone Arena': Venues.BLUNDSTONE,
    'Stadium Australia': Venues.STDAUS,
    'W.A.C.A. Ground': Venues.WACA,
    'Bellerive Oval': Venues.BLUNDSTONE,
    'Sydney Showground Stadium': Venues.SYDSHOWGROUNDS,
    'Accor Stadium': Venues.ACCOR,
    'Norwood Oval': Venues.NORWOOD,
    'Adelaide Hills': Venues.ADELAIDEHILLS,
    'Heritage Bank Stadium': Venues.HERITAGEBANK,
    'ENGIE Stadium': Venues.ENGIE, //nee. Sydney Park
    'People First Stadium': Venues.PEOPLEFIRST, //nee.HeritageBank
    'Alberton Oval': Venues.ALBERTON,
    'Sullivan Logistics Stadium': Venues.LEEDEVILLE, //nee Leederville Oval
    'Barossa Park': Venues.BAROSSA,
    'Ninja Stadium': Venues.NINJA,
    'Hands Oval': Venues.HANDS,

    //WAFL Venues
    'Pentanet Stadium': Venues.PENTANET,
    'Leederville Oval': Venues.LEEDEVILLE,
    'Lane Group Stadium': Venues.LANEGROUP,
    'Fremantle Community Bank Oval': Venues.FREMANTLECBO,
    'Mineral Resources Park': Venues.MINERALRES,
    'Revo Fitness Stadium': Venues.REVO,
    'Steel Blue Oval': Venues.STEELBLUE,
    'New Choice Homes Park': Venues.NEWCHOICE,
    Broome: Venues.BROOME,
    'Geraldton (WA)': Venues.GERALDTON,
    WACA: Venues.WACA,
    'To Be Confirmed': Venues.TBC,
    TBC: Venues.TBC,
}

export const TeamNameShape = z.union([
    z.literal('ADEL'),
    z.literal('BRIS'),
    z.literal('CARL'),
    z.literal('COLL'),
    z.literal('ESS'),
    z.literal('FRE'),
    z.literal('GEEL'),
    z.literal('GC'),
    z.literal('GWS'),
    z.literal('HAW'),
    z.literal('MELB'),
    z.literal('NM'),
    z.literal('PORT'),
    z.literal('RICH'),
    z.literal('STK'),
    z.literal('SYD'),
    z.literal('WCE'),
    z.literal('WB'),
    z.literal('E.FRE'),
    z.literal('CLA'),
    z.literal('W.PER'),
    z.literal('E.PER'),
    z.literal('PEEL'),
    z.literal('SUBI'),
    z.literal('SWA'),
    z.literal('PER'),
    z.literal('S.FRE'),
])

// Our in domain statuses do not match exactly with Champion data's statuses
export const statusMap: Record<ChampionMatchStatus, MatchStatus> = {
    scheduled: 'scheduled',
    prematch: 'pre-match',
    playing: 'in-progress',
    postmatch: 'in-progress',
    complete: 'completed',
    suspended: 'suspended',
    postponed: 'suspended',
    cancelled: 'cancelled',
}

export const MatchStatusShape = z.union([
    z.literal('scheduled'),
    z.literal('prematch'),
    z.literal('playing'),
    z.literal('postmatch'),
    z.literal('complete'),
    z.literal('suspended'),
    z.literal('cancelled'),
    z.literal('postponed'),
])

export type ChampionMatchStatus = z.TypeOf<typeof MatchStatusShape>
