import React from 'react'
import { Box, Text, Button } from '@chakra-ui/react'
import { CheckCircleIcon, WarningIcon, ViewOffIcon } from '@chakra-ui/icons'
import { GiPodiumWinner } from 'react-icons/gi'
import { useHistory } from 'react-router-dom'
import { CardComponent } from '../components/CardComponent'
import { Season } from '../data'

export function PrizeWinnersSection({ season }: { season: Season }) {
    const history = useHistory()

    return (
        <Box>
            <Text fontSize={'x-large'}>Prize Winners</Text>
            <CardComponent
                title="Prize Winners"
                content={
                    season.winners ? (
                        <Box display="flex" flexDir="row" alignItems="center">
                            {season.winners.hideWinners ? (
                                <>
                                    <ViewOffIcon color="grey" mr="1" />
                                    <Text opacity="0.7">Winners hidden</Text>
                                </>
                            ) : (
                                <>
                                    <CheckCircleIcon color="green" mr="1" />

                                    <Text opacity="0.7">
                                        Round{' '}
                                        {season.winners.roundWinners
                                            .map((w) => w.round)
                                            .filter(
                                                (x) =>
                                                    x !== undefined &&
                                                    x !== null,
                                            )
                                            .sort((a, b) => b - a)
                                            .find(
                                                (x) =>
                                                    x !== undefined &&
                                                    x !== null,
                                            ) ?? 'N/A'}
                                    </Text>
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box display="flex" flexDir="row" alignItems="center">
                            <WarningIcon color="#D22828" mr="1" />

                            <Text opacity="0.7">None Configured</Text>
                        </Box>
                    )
                }
                action={
                    <Button
                        colorScheme="blue"
                        onClick={() =>
                            history.push(
                                `/seasons/${season.sport}_${season.season}/winners`,
                            )
                        }
                        leftIcon={<GiPodiumWinner />}
                    >
                        Update
                    </Button>
                }
            />
        </Box>
    )
}
