import React from 'react'
import { Box, Text } from '@chakra-ui/react'

type CardProps = {
    title: string
    action: JSX.Element
    content: JSX.Element
}

export function CardComponent({ title, content, action }: CardProps) {
    return (
        <Box
            border="1px"
            borderColor="#65707d77"
            borderRadius={10}
            width={420}
            height={100}
            p="2"
            display="flex"
            gap="2"
        >
            <Box
                display="flex"
                flexDir="column"
                justifyContent="center"
                flex="1"
            >
                <Text fontSize="large">{title}</Text>
                {content}
            </Box>
            <Box margin="auto">{action}</Box>
        </Box>
    )
}
