import { useMutation, useQuery } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../environments/environment'
import {
    GameDTO,
    MatchStatus,
    TeamToWin,
    TipStatus,
    abbreviationToNameMap,
} from '@the-game/api-interfaces'
import { useToast } from '@chakra-ui/react'

export function useGetCompetitionsByKeyword({
    keyword,
    subject,
}: {
    keyword: string
    subject?: string
}) {
    const { oidcUser } = useReactOidc()
    return useQuery(
        ['competitions-by-keyword', keyword, subject],
        async () => {
            const resp = await fetch(
                `${environment.apiGatewayUrl}/competitions-by-keyword?keyword=${keyword}&subject=${subject}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                },
            )

            if (resp.status !== 200) {
                throw new Error('Error retrieving user details')
            }

            return resp.json() as Promise<any[]>
        },
        { enabled: false },
    )
}

export function usePostAddToCompetition() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()
    return useMutation(
        async ({
            competitionId,
            subject,
            email,
        }: {
            competitionId: string
            subject?: string
            email?: string
        }) => {
            return new Promise((resolve, reject) => {
                fetch(`${environment.apiGatewayUrl}/add-to-competition`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        competitionId,
                        subject,
                        email,
                    }),
                })
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title:
                                    'Oh no, something went wrong user to competition...',
                                description: `Please ensure the email or user sub is correct, and contact the dev team if the problem persists. ${
                                    fetchedResp?.message
                                        ? fetchedResp?.message
                                        : ''
                                }`,
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Error adding user to competition.',
                            )
                        } else {
                            toast({
                                title: 'Success',
                                description: 'User added to competition',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Error adding user to competition.')
                    })
            })
        },
    )
}

export function usePostUserTips({
    subject,
    sport,
    season,
    roundNumber,
    tips,
    margin,
}: {
    subject: string
    sport: string
    season: string
    roundNumber: number
    tips: Record<string, TeamToWin>
    margin: number
}) {
    const { oidcUser } = useReactOidc()
    return useMutation(async () => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/save-user-tips`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subject,
                    sport,
                    season,
                    roundNumber,
                    tips,
                    margin,
                }),
            })
                .then(async (resp) => {
                    const fetchedRespText = await resp.text()
                    let fetchedResp: any
                    try {
                        fetchedResp = await JSON.parse(fetchedRespText)
                    } catch (e) {
                        fetchedResp = {}
                    }

                    if (resp.status !== 200) {
                        reject({
                            error:
                                fetchedResp.error ?? 'Error saving user tips.',
                            failed: fetchedResp.failed ?? [],
                        })
                    }
                    resolve(fetchedResp)
                })
                .catch(() => {
                    reject({ error: 'Error saving user tips.' })
                })
        })
    })
}

export function useGetUserInfoByEmail() {
    const { oidcUser } = useReactOidc()
    return useMutation(
        async (email: string): Promise<UserByEmailDTO> => {
            const encodedEmail = encodeURIComponent(email)
            return new Promise((resolve, reject) => {
                fetch(
                    `${environment.apiGatewayUrl}/user-info-by-email?email=${encodedEmail}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${oidcUser.access_token}`,
                            'Content-Type': 'application/json',
                        },
                    },
                )
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedErrorResponse: {
                                message?: string
                            } = await resp.json()
                            reject(
                                fetchedErrorResponse?.message
                                    ? fetchedErrorResponse.message
                                    : 'Error retrieving user details.',
                            )
                        } else {
                            const fetchedUser: UserByEmailDTO = await resp.json()
                            resolve(fetchedUser)
                        }
                    })
                    .catch(() => {
                        reject('Error retrieving user details.')
                    })
            })
        },
    )
}

export function useGetMatchInfo(sport: string, season: string) {
    const { oidcUser } = useReactOidc()

    return useQuery(['matches', sport, season], async () => {
        const resp = await fetch(
            `${environment.apiGatewayUrl}/match-info?sport=${sport}&season=${season}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
            },
        )

        if (resp.status !== 200) {
            throw new Error('Error retrieving match info')
        }

        return resp.json() as Promise<MatchInfoDTO>
    })
}

export function useGetGames() {
    const { oidcUser } = useReactOidc()

    return useQuery(['games'], async () => {
        const resp = await fetch(
            `${environment.apiGatewayUrl}/games?includeUnpublished=true`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                cache: 'no-store',
            },
        )
        if (resp.status !== 200) {
            throw new Error('Error retrieving match info')
        }
        return resp.json() as Promise<Season[]>
    })
}

export function useSendPersonalNotification() {
    const { oidcUser } = useReactOidc()
    return useMutation(async (body: string) => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/send-personal-notification`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body,
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        reject({
                            error: 'Error sending personal notificaiton.',
                        })
                    }
                    resolve(resp)
                })
                .catch(() => {
                    reject({ error: 'Error sending personal notificaiton.' })
                })
        })
    })
}

export function useCreateSeason() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()
    return useMutation(async (body: string) => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/create-season`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body,
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        toast({
                            title:
                                'Oh no, something went wrong creating season',
                            description:
                                'Please check the console and contact the dev team.',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        reject({
                            error: 'Error creating new season.',
                        })
                    } else {
                        toast({
                            title: 'Success',
                            description: 'Season Created!',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    resolve(resp)
                })
                .catch(() => {
                    reject({ error: 'Error creating new season.' })
                })
        })
    })
}

export function useUpdateUserEmailAuth({
    oldEmail,
    newEmail,
}: {
    oldEmail: string
    newEmail: string
}) {
    const { oidcUser } = useReactOidc()
    return useMutation(async () => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/update-user-email-auth`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldEmail,
                    newEmail,
                }),
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        reject({
                            error: 'Error updating user email.',
                        })
                    }
                    resolve(resp)
                })
                .catch(() => {
                    reject({ error: 'Error updating user email.' })
                })
        })
    })
}

export function mapTeamName(code: string): string {
    return abbreviationToNameMap[code]
}

export function useUpdateCompetition({
    competitionCode,
    lockoutRound,
}: {
    competitionCode: string
    lockoutRound: number
}) {
    const { oidcUser } = useReactOidc()
    return useMutation(async () => {
        return new Promise((resolve, reject) => {
            console.log('Update Competition:', competitionCode)
            fetch(`${environment.apiGatewayUrl}/competition/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    competitionCode,
                    lockoutRound,
                }),
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        reject({
                            error: 'Error updating round deadline.',
                        })
                    }
                    resolve(resp)
                })
                .catch(() => {
                    reject({ error: 'Error updating round deadline.' })
                })
        })
    })
}

export type AvailableGameDTO = GameDTO & { rounds: MatchDTO[] }

export type UserTeamToWin = TeamToWin

export interface MatchInfoDTO {
    matches: {
        [id: string]: MatchDTO
    }
}

export type SportName = 'Cricket' | 'AFL' | 'WAFL' | 'AFLW' | 'NRL'
export type GameName = 'Tipping' | 'Picking'
export type LaunchType = 'Soft Launch' | 'Full Launch'

export interface Season {
    sport: SportName
    season: string
    game: GameName
    status: SeasonStatus
    totalRounds: number
    currentRound: number
    highestOpenRound: number
    published: boolean
    locked: boolean
    config?: SeasonConfig
    winners?: SeasonWinners
}

export interface SeasonConfig {
    sendGridId?: string
    ingestionCompId: string
    tabTouchCompId?: string
    termsAndConditions: string
    prizing: Prize[]
    expertTippingCompId?: string
    nameOverride?: string
    launchType?: LaunchType
}

export interface MajorPrizeWinner {
    prize: string
    winner: string
}

export interface RoundWinner {
    round: number
    type: 'finalist' | 'prize'
    name: string
}

export interface SeasonWinners {
    hideWinners?: boolean
    majorPrizeWinners: MajorPrizeWinner[]
    maxRounds: number
    roundWinners: RoundWinner[]
}

export interface NewRound {
    start: Date | null
    end: Date | null
    locked?: boolean
}

export interface Round {
    roundNumber: number
    start: string
    end: string
}

export interface Prize {
    title: string
    content: string
    subtext?: string
    image?: string
}

export enum SeasonStatus {
    NotStarted = 'not-started',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export type SeasonState =
    | 'draft'
    | 'published'
    | 'early-bird'
    | 'in-season'
    | 'completed'
    | 'archived'

export const seasonStateColorMap: Record<SeasonState, string> = {
    draft: 'yellow',
    published: 'orange',
    'early-bird': 'messenger',
    'in-season': 'green',
    completed: 'purple',
    archived: 'gray',
}

// "blue" | "cyan" | "gray" | "green" | "orange" | "pink" | "purple" | "red" | "teal" | "yellow"

export interface MatchDTO {
    isFeatured: boolean
    matchId: string
    homeTeam: string
    awayTeam: string
    venue: string | undefined
    startTime: string | undefined
    sport: string
    season: string
    round: number
    winner: string | undefined
    winningMargin: number | undefined
    status: MatchStatus
    selectedTip: TipStatus
    isLocked: boolean
}

export interface UserByEmailDTO {
    subject: string
    email: string | undefined
    username: string | undefined
    registrations: GameRegistrationDTO[]
    hasPlacedTips: boolean
    margins: MarginDTO[]
    competitions: CompetitionDTO[]
    availableGames: AvailableGameDTO[]
    auditLogs: AuditLogDTO[]
}

export interface GameRegistrationDTO {
    sport: string
    season: string
    game: string
    joined: string
    team: string
    thirdPartyComms: any
}

export interface MarginDTO {
    sport: string
    season: string
    roundNumber: number
    matchId: string
    tippedMargin: number
}

export interface MemberDTO {
    name: string
    teamFlag: string
    userId: string
    username: string
}

export interface CompetitionDTO {
    pk: string
    sport: string
    game: string
    competitionName: string
    season: string
    isJoined?: boolean
    members?: MemberDTO[]
    currentUserRank?: number
    currentUserRankOutOf?: number
    ownerSub: string
    lockoutRound: number
}

export interface AuditLogDTO {
    sk: string | undefined
    event: string | undefined
}
