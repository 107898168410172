import { Flex, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export type SidebarComponents = {
    active: string
    setActive: (arg0: string) => void
    tabs: TabComponents[]
}

export type TabComponents = {
    link: string
    name: string
    icon: JSX.Element
    content: JSX.Element
}

export function SidebarWindow({ active, setActive, tabs }: SidebarComponents) {
    const tabBackground = useColorModeValue('gray.100', 'gray.700')
    const tabHighlight = useColorModeValue('blue.500', 'blue.300')

    const renderedTabs = tabs.map((t) => {
        const selected = active === t.link
        return (
            <Flex
                key={t.link}
                paddingX="2.5"
                paddingY="1"
                background={selected ? tabBackground : 'transparent'}
                borderStyle="solid"
                borderLeftColor={selected ? tabHighlight : 'transparent'}
                borderLeftWidth={2.5}
                borderRightRadius="md"
                cursor="pointer"
                alignItems="center"
                gap="3"
                transitionDuration="0.15s"
                onClick={() => setActive(t.link)}
            >
                {t.icon}
                {t.name}
            </Flex>
        )
    })

    const activeTab = tabs.find((t) => t.link === active)

    return (
        <Flex flexDirection="row" marginX="auto">
            <Flex
                flexDirection={'column'}
                gap={2}
                width="80"
                minW="60"
                marginTop="2"
            >
                {renderedTabs}
            </Flex>
            <Flex
                width="100%"
                // borderLeftWidth={1.5}
                paddingLeft="4"
                marginLeft="4"
                paddingBottom="24"
                flexDirection="column"
            >
                {activeTab?.content}
            </Flex>
        </Flex>
    )
}
